.mainContent {
  width: 400px;
  position: absolute;
  right: 0;
  bottom: 20px;
  top: 100px;
  display: flex;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 4px;
  pointer-events: all;
  transition: right 2s ease;
  border-radius: 4px 0 0 4px;
}
.mainContent .head {
  line-height: 32px;
  font-size: 18px;
  flex-shrink: 0;
  color: #FFF;
}
.mainContent .head .attach {
  width: 150px;
  float: right;
}
.mainContent .blockTitle {
  font-size: 14px;
  margin-left: 6px;
  border-bottom: 1px solid #FFF;
  color: white;
}
.left_click_btn {
  position: absolute;
  top: 48%;
  left: -18px;
  background: var(--themeColor);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px 0px;
  border-radius: 4px 0 0 4px;
  cursor: pointer;
}
.left_click_btn img {
  width: 18px;
  transform: rotate(180deg);
}
.left_click_btn_active {
  transition: all 2s ease;
}
.left_click_btn_active img {
  transform: rotate(0deg);
}
.mainContent2,
.index {
  position: absolute;
  right: 0;
  top: 60px;
  height: calc(100vh - 60px);
  width: 750px;
  background: rgba(255, 255, 255, 0.9);
  pointer-events: all;
  transition: right 1s ease;
}
.mainContent2 .mian_title,
.index .mian_title {
  font-size: 18px;
  width: 100%;
  padding: 10px 10px;
  display: flex;
  font-weight: 600;
  position: relative;
  margin: 0 0 0 10px;
  line-height: 18px;
}
.mainContent2 .mian_title:before,
.index .mian_title:before {
  content: "";
  position: absolute;
  width: 2px;
  height: 18px;
  top: 11px;
  background: #4261ED;
  left: 1px;
}
.mainContent2 .gary_line,
.index .gary_line {
  width: calc(100% - 20px);
  height: 2px;
  position: relative;
  left: 10px;
  bottom: 0;
  background-color: rgb(247 247 250);
}
.mainContent2 .gary_line::after,
.index .gary_line::after {
  content: "";
  position: absolute;
  width: 8px;
  height: 2px;
  top: 0px;
  background: #e0e2e3;
  right: 0;
}
.mainContent2 .gary_line::before,
.index .gary_line::before {
  content: "";
  position: absolute;
  width: 8px;
  height: 2px;
  top: 0;
  background: #e0e2e3;
  left: 0;
}
.mainContent2 {
  width: 99%!important;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.mainContent2 .mian_title > div > span {
  margin-left: 10px;
  font-weight: 500;
  font-size: 14px;
}
.mainContent2 .row {
  height: 100%;
}
.mainContent2 .row-item1 {
  width: calc(100% - 12px);
  height: 18%;
  position: relative;
  border-radius: 4px;
  background: #ffffff;
  box-shadow: 0 0 10px 1px rgba(66, 97, 237, 0.1);
  margin: 10px;
}
.mainContent2 .row-item1 .date {
  position: absolute;
  right: 25px;
  top: 7px;
  font-weight: 500;
}
.mainContent2 .row-item1 .eleAll {
  width: 80%;
  height: 76%;
  display: flex;
  margin: auto;
  justify-content: space-around;
}
.mainContent2 .row-item1 .eleAll .all-item {
  width: 40%;
  height: 80%;
  padding: 2px;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: space-around;
  border-radius: 5px;
  background: url("../../assets/img/item3.png");
  background-repeat: no-repeat;
  background-size: 100%;
  color: #fff;
}
.mainContent2 .row-item1 .eleAll .all-item .all-left P,
.mainContent2 .row-item1 .eleAll .all-item .all-right P {
  font-weight: 600;
  font-size: 20px;
}
.mainContent2 .row-item1 .eleAll .all-item .all-right {
  border-left: 1px solid #fff;
  width: 45%;
  position: relative;
}
.mainContent2 .row-item1 .eleAll .all-item .all-right img {
  position: absolute;
  top: 10px;
}
.mainContent2 .row-item1 .eleAll .all-item2 {
  width: 20%;
}
.mainContent2 .row-item2 {
  width: calc(100% - 12px);
  height: 40%;
  border-radius: 4px;
  background: #ffffff;
  box-shadow: 0 0 10px 1px rgba(66, 97, 237, 0.1);
  margin: 10px;
}
.mainContent2 .row-item2 .item-top {
  width: 100%;
  height: 84%;
}
.mainContent2 .row-item2 .item-top0 {
  display: flex;
  justify-content: center;
  align-items: center;
}
.mainContent2 .row-item2 .item-top0 .item_top_dian {
  background: url("../../assets/img/dian_bg2.png") no-repeat;
  width: 292px;
  height: 136px;
  position: relative;
  font-size: 14px;
  color: #fff;
}
.mainContent2 .row-item2 .item-top0 .item_top_dian p:nth-child(1) {
  position: absolute;
  top: 10px;
  left: 20px;
}
.mainContent2 .row-item2 .item-top0 .item_top_dian p:nth-child(2) {
  position: absolute;
  bottom: 10px;
  left: 100px;
}
.mainContent2 .row-item3 {
  border-radius: 4px;
  background: #ffffff;
  box-shadow: 0 0 10px 1px rgba(66, 97, 237, 0.1);
  margin: 10px;
  width: calc(100% - 12px);
  height: 37%;
  position: relative;
}
.mainContent2 .row-item3 .switch {
  height: 40px;
  display: flex;
  position: absolute;
  left: 150px;
  top: 0;
  margin: 0;
  font-weight: 500;
  font-size: 14px;
  padding: 0;
}
.mainContent2 .row-item3 .switch > p {
  padding: 9px 10px;
  margin: 0 0 0 30px;
  color: #788CA1;
  cursor: pointer;
}
.mainContent2 .row-item3 .isSelected {
  z-index: 10;
  color: #4261ED !important;
}
.mainContent2 .row-item3 .item-ele {
  width: 100%;
  height: 84%;
  display: flex;
  text-align: center;
  text-align: left;
}
.mainContent2 .row-item3 .ele-left {
  flex: 1;
  overflow: auto;
  padding-top: 10px;
}
.mainContent2 .row-item3 .ele-left .rate-item {
  width: 100%;
  display: flex;
  overflow: auto;
  padding: 10px;
  border-bottom: 1px solid #f5f5f5;
}
.mainContent2 .row-item3 .ele-left .rate-item p {
  width: 80%;
  text-align: left;
  padding-left: 16px;
}
.mainContent2 .row-item3 .ele-left .rate-item .rank {
  width: 14%;
  border: 1px solid #c4ccD6;
  border-radius: 4px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  border-radius: 5px;
  padding: 2px 8px;
  color: #4261ED;
}
.mainContent2 .row-item3 .ele-left .rate-item .rank img {
  width: 14px;
  height: 14px;
}
.mainContent2 .row-item3 .ele-left:before {
  content: "";
  width: 1px;
  height: 70%;
  position: absolute;
  right: 0;
  top: 15%;
}
.mainContent2 .row-left {
  flex: 2;
}
.mainContent2 .row-right {
  flex: 3;
}
.mainContent2 .title {
  padding-left: 20px;
  padding-top: 4px;
  font-size: 18px;
  font-weight: 500;
  background: transparent;
}
