
	@import "../../variables.less";
	@import "../../resource/common.less";
	.mainContent2 {
        width: 99%!important;
		display: flex;
		flex-direction: row;
		justify-content: center;
        .mian_title{
            &>div{
                &>span{
                    margin-left: 10px;
                    font-weight: 500;
                    font-size:@fontSizeC;
                    // color: @baseColor;
                }
            }
        }
		.row {
			height: 100%;
		}
		.row-item1 {
			width: calc(100% - 12px);
			height: 18%;
			position: relative;
            border-radius: 4px;
            background:@contentBg;
            box-shadow: 0 0 10px 1px @boxshadow;
            margin: 10px;
			.date {
				position: absolute;
				right: 25px;
				top: 7px;
                font-weight: 500;
			}
			.eleAll {
				width: 80%;
				height: 76%;
				display: flex;
				margin: auto;
				justify-content: space-around;
				.all-item {
					width: 40%;
					height: 80%;
					padding: 2px;
					display: flex;
					text-align: center;
					align-items: center;
					justify-content: space-around;
					border-radius: 5px;
                    // @baseColor2: rgba(red(@baseColor), green(@baseColor), blue(@baseColor), 0.7);
                    // background: linear-gradient(to right, @baseColor, @baseColor2);
                    background: url("../../assets/img/item3.png");background-repeat: no-repeat;background-size: 100%;
					color: #fff;
                    .all-left, .all-right{
                        P{
                            font-weight: 600;
                            font-size: 20px;
                        }
                    }
                    .all-right{
                        border-left: 1px solid #fff;
                        width: 45%;
                        position:relative;
                        img{
                            position:absolute;
                            top: 10px;
                        }
                    }
				}
				.all-item2 {
					width: 20%;
				}
			}
		}
		.row-item2 {
			width: calc(100% - 12px);
			height: 40%;
            border-radius: 4px;
            background:@contentBg;
            box-shadow: 0 0 10px 1px @boxshadow;
            margin: 10px;
			.item-top{
				width: 100%;
				height: 84%;
			}
            .item-top0 {
                display: flex;
                justify-content: center;
                align-items: center;
                .item_top_dian{
                    background: url("../../assets/img/dian_bg2.png") no-repeat;
                    width: 292px;
                    height: 136px;
                    position:relative;
                    font-size: 14px;
                    color:#fff;
                    p:nth-child(1){
                        position:absolute;
                        top: 10px;
                        left: 20px;
                    }
                    p:nth-child(2){
                        position:absolute;
                        bottom:10px;
                        left: 100px;
                    }
                }
            }
		}
		.row-item3 {
            border-radius: 4px;
            background:@contentBg;
            box-shadow: 0 0 10px 1px @boxshadow;
            margin: 10px;
			width: calc(100% - 12px);
			height: 37%;
			position: relative;
			.switch {
                height: 40px;
                display:flex;
                position: absolute;
                left: 150px;
                top: 0;
                margin: 0;
                font-weight: 500;
                font-size: @fontSizeC;
                padding: 0;
                &>p{
                    padding: 9px 10px;
                    margin: 0 0 0 30px;
                    color:@colorGary;
                    cursor:pointer;
                }
			}
			.isSelected {
				// border-bottom: 2px solid @baseColor;
                z-index: 10;
                color: @baseColor!important;
			}
			.item-ele {
				width: 100%;
				height: 84%;
				display: flex;
				text-align: center;
                text-align:left;
			}
			.ele-left {
				flex: 1;
				overflow: auto;
				padding-top: 10px;
				.rate-item {
					width: 100%;
					display: flex;
					overflow: auto;
                    padding: 10px;
                    border-bottom: 1px solid #f5f5f5;
					p {
						width: 80%;
						text-align: left;
						padding-left: 16px;
					}
					.rank {
						width: 14%;
                        border: 1px solid #c4ccD6;
                        border-radius: 4px;
						display: flex;
						align-items: center;
						text-align: center;
						justify-content: center;
						border-radius: 5px;
						padding: 2px 8px;
                        color: @baseColor;
						img {
							width: 14px;
							height: 14px;
						}
					}
				}
				&:before {
					content: "";
					width: 1px;
					height: 70%;
					position: absolute;
					right: 0;
					top: 15%;
				}
			}
		}
		.row-left {
			flex: 2;
		}
		.row-right {
			flex: 3;
		}
		.title {
			padding-left: 20px;
			padding-top: 4px;
			font-size: 18px;
			font-weight: 500;
			background: transparent;
		}
	}
